import { IModel } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Deferred } from 'src/app/common/deferred/deferred';
import {
  ArticleModelName,
  GroupModelName,
  PostalCodeModelName,
  RegionModelName,
  TemplateModelName,
  UsersModelName,
} from 'src/app/shared/models/model-names';

import { IState } from '../contracts/state/state';
import { IStateExtension } from '../contracts/state/state-extension';
import { StateExtensionAction } from '../contracts/state/state-extension-action';
import { IAppState } from '../contracts/state/state-model';
import { IStateRegistry } from '../contracts/state/state-registry';
import { IStateRegistryPersister } from '../contracts/state/state-registry-persister';
import { ObservableModel } from '../viewmodel/observable-model';
import { StateRegistryPersister } from './state-registry-persister';

@Injectable({ providedIn: 'root' })
export class StateRegistry implements IStateRegistry {
  // tslint:disable-next-line:no-object-literal-type-assertion
  /** TODO: type of state is [modelName]:[Path]:ObservableModel<IModel | IModel[]> not IAppState?*/
  private _appState: IAppState = {} as IAppState;
  private _extensions: Record<string, IStateExtension<IModel>[]> = {};
  private _isHydratedDefered = new Deferred<void>();
  public readonly isHydrated = this._isHydratedDefered.promise;

  private _withArchive = [
    ArticleModelName,
    UsersModelName,
    GroupModelName,
    RegionModelName,
    PostalCodeModelName,
    TemplateModelName,
  ];

  constructor(
    @Inject(StateRegistryPersister)
    private _statePersister: IStateRegistryPersister
  ) {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      this._statePersister.subscribe(({ key, value }) => {
        const modelName = key.substring(0, key.indexOf(':'));
        const path = key.substring(key.indexOf(':') + 1);

        this.update(modelName, path, value);
      });

      await this._statePersister.rehydrate();
      this._isHydratedDefered.resolve();
    })();
  }

  public register(modelName: string, state: IState<IModel>, extensions: IStateExtension<IModel>[]): void {
    this._appState[modelName] = this._appState[modelName] || state;
    this._extensions[modelName] = extensions;

    if (!extensions) {
      return;
    }

    extensions.forEach(extension => extension.setRegistry(this));
  }

  public select<TValue, TModel extends IModel>(modelName: string, path: string): Observable<TValue> {
    const state: IState<TModel> = this._appState[modelName];

    if (!state) {
      return throwError(
        () =>
          new Error(
            `No state found for id ${modelName}. Make sure you first register the state inside your ComponentModel.`
          )
      );
    }

    const value: ObservableModel<TValue> = state[path];

    if (!value) {
      return of(null);
    }

    return value.data$;
  }

  public get<TValue, TModel extends IModel>(modelName: string, path: string): TValue | undefined {
    const state: IState<TModel> = this._appState[modelName];

    if (!state) {
      throw new Error(
        `No state found for id ${modelName}. Make sure you first register the state inside your ComponentModel.`
      );
    }

    const value: ObservableModel<TValue> = state[path];

    if (!value) {
      return;
    }

    return value.get();
  }

  public async createBySync(modelName: string, path: string, item: IModel): Promise<void> {
    const items = await this.writeToStateWithoutPersister(modelName, path, item);
    await this._statePersister.update(modelName, path, items);
  }

  public async writeToStateWithoutPersister(modelName: string, path: string, item: IModel): Promise<IModel[]> {
    if (!item) {
      return;
    }

    this._appState[modelName] = this._appState[modelName] || {};
    this._appState[modelName][path] = this._appState[modelName][path] || new ObservableModel<IModel[]>([], false);

    const model: ObservableModel<IModel[]> = this._appState[modelName][path];
    const observedModels: IModel[] = model.get();

    if (observedModels.some(observedModel => observedModel._id === item._id)) {
      return;
    }

    const items = [...Array.from(observedModels), item];
    model.set(items);
    return items;
  }

  public async updateBySync(modelName: string, path: string, models: IModel[]): Promise<void> {
    if (!this._appState[modelName] || !this._appState[modelName][path] || models.length === 0) {
      return;
    }

    const observedModel: ObservableModel<any> = this._appState[modelName][path];

    for (const model of models) {
      if (!this._withArchive.includes(modelName) && model.archived) {
        await this.removeBySync(modelName, path, [model]);
        continue;
      }
      const items: IModel[] | IModel = observedModel.get();
      if (Array.isArray(items)) {
        const mappedItems = [...items.map(item => (item._id === model._id ? { ...item, ...model } : item))];
        observedModel.set(mappedItems);
      } else {
        if (items._id === model._id) {
          const updatedModel = { ...items, ...model };
          observedModel.set(updatedModel);
        }
      }
    }
    await this._statePersister.update(modelName, path, observedModel.get());
  }

  public async removeBySync(modelName: string, path: string, models: IModel[]): Promise<void> {
    if (!this._appState[modelName] || !this._appState[modelName][path] || !models || !models.length) {
      return;
    }

    const observedModel: ObservableModel<any> = this._appState[modelName][path];

    const items = observedModel.get();
    const filteredItems = items.filter(item => !models.some(model => model._id === item._id));

    observedModel.set(filteredItems);
    await this._statePersister.update(modelName, path, filteredItems);
  }

  public async removeAllFromState(modelName: string, path: string): Promise<void> {
    if (!this._appState[modelName] || !this._appState[modelName][path]) {
      return;
    }
    const observedModel: ObservableModel<any> = this._appState[modelName][path];
    observedModel.set([]);
    await this._statePersister.update(modelName, path, []);
  }

  public update<TValue>(modelName: string, path: string, value: TValue): void {
    if (!value) {
      return;
    }

    this._appState[modelName] = this._appState[modelName] || {};
    this._appState[modelName][path] = this._appState[modelName][path] || new ObservableModel<any>(undefined, false);

    const model: ObservableModel<TValue> = this._appState[modelName][path];
    model.set(value);
    // tslint:disable-next-line: no-floating-promises
    this._statePersister.update(modelName, path, value);
  }

  async runExtension(
    modelName: string,
    action: StateExtensionAction,
    items: IModel[],
    metadata?: { [key: string]: any }
  ): Promise<void> {
    const extensions: IStateExtension<IModel>[] = this._extensions[modelName];

    if (!extensions || !items || !items.length) {
      return;
    }

    switch (action) {
      case StateExtensionAction.create:
        await Promise.all(extensions.map(extension => extension.afterCreate(items)));
        break;
      case StateExtensionAction.update:
        await Promise.all(extensions.map(extension => extension.afterUpdate(items, metadata)));
        break;
    }
  }
}
