import { IService, IServiceQuantityPerUnit, ServiceType, TaskOptions } from '@alberta/konexi-shared';
import { ignore } from '@common/tracking/tracker';
import { BaseViewModel } from '@common/viewmodel/base-view-model';

export class ServiceViewModel extends BaseViewModel implements IService {
  taskOptions: TaskOptions;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  name: string;
  description: string;
  additionalDescription: string;
  therapyIds: number[] = [];
  serviceType: ServiceType;
  billingUnits: IServiceQuantityPerUnit[];

  @ignore()
  checked: boolean;
}
