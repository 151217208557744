import { Injectable } from '@angular/core';
import { getHolidays } from 'feiertagejs';

@Injectable()
export class HolidayService {
  /**
   * Fetches holidays for the given number of future years.
   * @param years Number of future years to fetch (default: 5)
   * @returns Array of German public holidays
   */
  getHolidaysForYears(years: number = 5): any[] {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({ length: years + 1 }, (_, index) => (currentYear + index).toString());

    return yearList.flatMap(year => getHolidays(year, 'BUND'));
  }

  /**
   * Fetches holidays for a single given year.
   * @param year The year for which to fetch holidays (e.g., 2025)
   * @returns Array of German public holidays for that year
   */
  getHolidaysForYear(year: number): any[] {
    return getHolidays(year.toString(), 'BUND');
  }
}
