import {
  ErpStatus,
  Gender,
  IAddress,
  ICareGiver,
  IGroup,
  IPatient,
  IPatientDoctorRelation,
  IPatientHospitalRelation,
  IPatientNursingHomeRelation,
  IPatientNursingServiceRelation,
  IPatientPayerRelation,
  IPatientPharmacyRelation,
  PatientDeactivationReason,
  Title,
} from '@alberta/konexi-shared';
import { IDeliveryInformation } from '@alberta/konexi-shared/dist/patient/deliveryInformation';
import moment from 'moment';
import { GroupDB } from 'src/app/common/repository/databases';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QueryService } from 'src/app/shared/services/query/query.service';

import { IPatientReferrer } from '@alberta/konexi-shared/dist/patient/patientReferrer';
import { TenantConfigService } from '@services/tenant-config/tenant-config.service';
import { createObject } from '../../../common/tracking/object-creator';
import { ignore, removeTime, tracked } from '../../../common/tracking/tracker';
import { CaregiverViewModel } from './caregiver-view-model';
import { PatientDoctorRelationViewModel } from './patient-doctor-relation-view-model';
import { PatientHospitalRelationViewModel } from './patient-hospital-relation-view-model';
import { PatientNursingHomeRelationViewModel } from './patient-nursing-home-relation-view-model';
import { PatientNursingServiceRelationViewModel } from './patient-nursing-service-relation-view-model';
import { PatientPayerRelationViewModel } from './patient-payer-relation-view-model';
import { PatientPharmacyRelationViewModel } from './patient-pharmacy-relation-view-model';

const PatientViewModelErrors = {
  ACCOUNT_MISSING: 'accountMissing',
  GROUP_MISSING: 'groupMissing',
  REGIONID_MISSING: 'regionIdMissing',
};

export class PatientViewModel extends BaseViewModel implements IPatient {
  city: string;
  postalCode: string;
  customerContactId: string;
  deliveryAddress: IAddress;
  deliveryInformation: IDeliveryInformation;
  // master data
  title: Title;

  gender: Gender;

  firstName: string;
  lastName: string;

  customerId: string;
  @removeTime()
  birthday: Date;

  address: string;
  additionalAddress: string;
  additionalAddress2: string;

  postalCodeId: string;

  phone: string;

  mobilePhone: string;
  fax: string;
  email: string;

  calorieDemand: number;
  dashboardInfo: string[];

  // relations

  @tracked({ factory: createObject(PatientPayerRelationViewModel) })
  payer: IPatientPayerRelation;

  /** aka ik-number */
  payerInstitutionCode: string;

  @tracked({ factory: createObject(PatientHospitalRelationViewModel) })
  hospital: IPatientHospitalRelation;

  primaryDoctorId: string;

  @tracked({ factory: createObject(PatientDoctorRelationViewModel) })
  primaryDoctor: IPatientDoctorRelation;

  nursingHomeId: string;

  @tracked({ factory: createObject(PatientNursingHomeRelationViewModel) })
  nursingHome: IPatientNursingHomeRelation;

  nursingServiceId: string;

  @tracked({ factory: createObject(PatientNursingServiceRelationViewModel) })
  nursingService: IPatientNursingServiceRelation;

  @tracked({ factory: createObject(PatientPharmacyRelationViewModel) })
  pharmacy: IPatientPharmacyRelation;

  @tracked({ factory: createObject(CaregiverViewModel) })
  careGivers: ICareGiver[] = [];

  // internal data

  regionId: string;

  fieldNurseId: string;

  // Kuma data
  nextVisit: Date;
  ivTherapy: boolean;
  classification: string;
  changeInSupplier: boolean;
  comment: string;
  pharmacyId: string;
  billingAddress: IAddress;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  accountingStatus: boolean;
  deactivationReason: PatientDeactivationReason;
  @removeTime()
  deactivationDate: Date;
  deactivationComment: string;
  erpStatus: ErpStatus;
  imported: boolean;
  resmedTenant: string;
  ignoreChangeForResmed: boolean;
  @ignore(true)
  validToDate: Date;
  additionalUserId: string;
  additionalUserIds: string[];
  careDegree: 1 | 2 | 3 | 4 | 5;
  status: string;
  additionalAttributes?: Record<string, any>;
  calendarResourceId: string;
  referrer: IPatientReferrer;
  accountingType?: 'full' | 'doNotBill';
  specializedServiceProviderBranchId?: string;

  @ignore(true)
  public get isDeactivated(): boolean {
    return this.deactivationReason > 0;
  }

  constructor() {
    super();
    this.customerId = '';
    this.birthday = null;
    this.accountingStatus = false;
    this.payer = new PatientPayerRelationViewModel();
    this.hospital = new PatientHospitalRelationViewModel();
    this.deliveryInformation = {} as IDeliveryInformation;
  }

  public async initializePatient(
    auth: AuthService,
    queryService: QueryService,
    tenantConfigService: TenantConfigService
  ) {
    this.accountingStatus = true;
    if (await tenantConfigService.shouldShowReferrer()) {
      const initalizedReferrer: IPatientReferrer = { type: undefined };
      this.referrer = initalizedReferrer;
    }
    const hasAccountId = auth.authentication && auth.authentication.account && auth.authentication.account._id;
    if (!this.fieldNurseId && hasAccountId) {
      this.fieldNurseId = auth.authentication.account._id;
    }
    if (auth.authentication && hasAccountId && auth.authentication.account.groups.length) {
      const group = await queryService.get<IGroup>(auth.authentication.account.groups[0], GroupDB);
      if (group) {
        if (!group.regionId) {
          throw new Error(PatientViewModelErrors.REGIONID_MISSING);
        }
        this.regionId = group.regionId;
        return;
      } else {
        throw new Error(PatientViewModelErrors.GROUP_MISSING);
      }
    } else {
      throw new Error(PatientViewModelErrors.ACCOUNT_MISSING);
    }
  }

  public getDeliveryStartTime() {
    if (this.deliveryInformation?.startTime) {
      return moment(`01.01.1970 ${this.deliveryInformation.startTime}`, 'DD.MM.YYYY HH:mm').utc().toDate();
    }
  }

  public setDeliveryStartTime(startTime: Date) {
    this.deliveryInformation = this.deliveryInformation ?? ({} as IDeliveryInformation);
    if (startTime) {
      this.deliveryInformation.startTime = moment(startTime).format('HH:mm');
    }
  }

  public setNursingHomeSubUnit(subUnitId: string) {
    this.createNursingHomeIfNecessary();
    this.nursingHome.subunitId = subUnitId;
    this.removeNursingHomeIfNecessary();
  }

  public setNursingHomeContact(contactId: string) {
    this.createNursingHomeIfNecessary();
    this.nursingHome.contactId = contactId;
    this.removeNursingHomeIfNecessary();
  }

  public setNursingServiceSubUnit(subUnitId: string) {
    this.createNursingServiceIfNecessary();
    this.nursingService.subunitId = subUnitId;
    this.removeNursingServiceIfNecessary();
  }

  public setNursingServiceContact(contactId: string) {
    this.createNursingServiceIfNecessary();
    this.nursingService.contactId = contactId;
    this.removeNursingServiceIfNecessary();
  }

  public setPrimaryDoctorContact(contactId: string) {
    this.createPrimaryDoctorIfNecessary();
    this.primaryDoctor.contactId = contactId;
    this.removePrimaryDoctorIfNecessary();
  }
  // Have to do this shit because ion Selection doesn't support false as boolean.
  // https://github.com/ionic-team/ionic/issues/12858
  set ivTherapySelection(value: number) {
    value ? (this.ivTherapy = true) : (this.ivTherapy = false);
  }
  get ivTherapySelection(): number {
    if (this.ivTherapy) {
      return 1;
    }
    if (this.ivTherapy === undefined || this.ivTherapy === null) {
      return null;
    }
    return 0;
  }
  set changeInSupplierSelection(value: number) {
    value ? (this.changeInSupplier = true) : (this.changeInSupplier = false);
  }
  get changeInSupplierSelection(): number {
    if (this.changeInSupplier) {
      return 1;
    }
    if (this.changeInSupplier === undefined || this.changeInSupplier === null) {
      return null;
    }
    return 0;
  }

  private createNursingHomeIfNecessary() {
    if (!this.nursingHome) {
      this.nursingHome = new PatientNursingHomeRelationViewModel();
    }
  }

  private removeNursingHomeIfNecessary() {
    if (this.nursingHome && !this.nursingHome.contactId && !this.nursingHome.subunitId) {
      this.nursingHome = null;
    }
  }

  private createNursingServiceIfNecessary() {
    if (!this.nursingService) {
      this.nursingService = new PatientNursingServiceRelationViewModel();
    }
  }

  private removeNursingServiceIfNecessary() {
    if (this.nursingService && !this.nursingService.contactId && !this.nursingService.subunitId) {
      this.nursingService = null;
    }
  }

  private createPrimaryDoctorIfNecessary() {
    if (!this.primaryDoctor) {
      this.primaryDoctor = new PatientDoctorRelationViewModel();
    }
  }

  private removePrimaryDoctorIfNecessary() {
    if (this.primaryDoctor && !this.primaryDoctor.contactId) {
      this.primaryDoctor = null;
    }
  }
}
