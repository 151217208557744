<div
  class="alberta-button-input-wrapper"
  [ngClass]="{
    'alberta-button-input-wrapper--alert': ngControl && ngControl.invalid,
    'alberta-button-input-wrapper--disabled': disabled,
  }"
  tappable
>
  <div class="button-content-wrapper" (click)="type === DateType.Time ? openTimePicker() : openMobiScroll()">
    <div
      class="icon-wrapper"
      *ngIf="!noIcon"
      [ngClass]="{
        'icon-wrapper--stdIcon': !icon,
      }"
    >
      <ion-icon [name]="icon ? icon : 'alberta-dot'"></ion-icon>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <span *ngIf="!date" class="placeholder"> {{ placeholder }}</span>
        <div class="inputs-wrapper bold" [class.hidden]="!date">
          <div
            class="date-wrapper bold"
            (click)="openMobiScroll()"
            tappable
            [style.display]="type !== DateType.Time ? 'unset' : 'none'"
            [style.flex]="type === DateType.Date ? 1 : 'unset'"
          >
            {{ date | date: dateFormatter }}
          </div>
          <div class="hyphen" *ngIf="type === 0">-</div>
          <div
            class="time-wrapper"
            [ngClass]="{ 'time-wrapper--only-time': type === DateType.Time }"
            [style.display]="type !== 1 ? 'unset' : 'none'"
          >
            <input
              #timeInput
              [value]="date | date: 'HH:mm'"
              class="time-input"
              [min]="minTime"
              [max]="maxTime"
              (click)="openTimePicker()"
              readonly
            />
            <ng-template #cancelBtn>
              <button class="timepickerBtn">{{ 'CANCEL_BUTTON' | translate }}</button>
            </ng-template>
            <ng-template #confirmBtn>
              <button class="timepickerBtn">{{ 'DONE_BUTTON' | translate }}</button>
            </ng-template>
          </div>
        </div>
        <div
          class="deleteButton"
          (click)="removeInput($event)"
          *ngIf="date != undefined && !disabled && !noDeleteButton"
        >
          <ion-icon name="alberta-delete"></ion-icon>
        </div>
      </div>
      <div [ngClass]="required ? 'title required-star' : 'title'">{{ title }} <span *ngIf="required">*</span></div>
    </div>
  </div>
  <div class="description" *ngIf="description">
    {{ description }}
  </div>
  <itl-error-display *ngIf="ngControl?.control" [control]="ngControl?.control"></itl-error-display>
</div>
<div *ngIf="type !== DateType.Time">
  <mbsc-datepicker
    [controls]="dateTimePickerControls"
    [colors]="holidays"
    #mobiscrollDateTimePicker
    style="display: none"
    [touchUi]="true"
    [showOnClick]="false"
    [showOnFocus]="false"
    [ngModel]="date"
    [defaultSelection]="preSelection"
    display="center"
    (onClose)="dateChanged($event)"
    (change)="handleChange($event)"
    timeFormat="HH:mm"
    returnFormat="iso8601"
    [min]="min"
    [max]="max"
  ></mbsc-datepicker>
</div>
<div *ngIf="type === DateType.Time">
  <mbsc-datepicker
    [controls]="['time']"
    #mobiscrollTimePicker
    style="display: none"
    [touchUi]="true"
    [showOnClick]="false"
    [ngModel]="date"
    [showOnFocus]="false"
    display="center"
    (onClose)="timeChanged($event)"
    (change)="handleChange($event)"
    timeFormat="HH:mm"
    returnFormat="iso8601"
    [minTime]="minTime"
    [maxTime]="maxTime"
    defaultSelection="12:00"
  ></mbsc-datepicker>
</div>
