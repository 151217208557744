import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as SentryAngular from '@sentry/angular';
import * as SentryCapacitor from '@sentry/capacitor';
import { AppflowLiveUpdatesService } from '@services/appflow-live-updates/appflow-live-updates.service';
import { SessionVaultService } from '@services/session-vault/session-vault.service';
import { TenantConfigService } from '@services/tenant-config/tenant-config.service';
import * as markerjs2 from 'markerjs2';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { environment } from 'src/environments/environment';
import { register as registerSwiper } from 'swiper/element/bundle';
import { PipelineRegistry } from './shared/pipeline/start/pipeline-registry';
import { AuthService } from './shared/services/auth.service';
import { StatusBarService } from './shared/services/statusbar.service';
import { TrackerService } from './shared/services/tracker.service';

registerSwiper();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public isLocked = false;
  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private appflowLiveUpdatesService: AppflowLiveUpdatesService,
    private environmentService: EnvironmentService,
    private readonly tracker: TrackerService,
    private _statusBarService: StatusBarService,
    private _pipelineRegistry: PipelineRegistry,
    private readonly router: Router,
    private readonly authService: AuthService,
    private zone: NgZone,
    private sessionVault: SessionVaultService,
    private navController: NavController,
    private _tenantConfigService: TenantConfigService
  ) {
    this.initializeApp();
    markerjs2.Activator.addKey(environment.markerjs2Key);
  }

  async initializeApp() {
    this.initTranslate();
    await this.platform.ready();
    this.initDeepLinks();
    await this.appflowLiveUpdatesService.setChannel();
    await this.environmentService.init();
    await this._pipelineRegistry.start();

    let dist = Capacitor.isNativePlatform() ? 'app' : 'web';
    dist = dist + '-' + this.environmentService.buildNumber;
    await this.initSentry(dist);
    this.tracker.setVersion(this.environmentService.branch, dist);

    if (Capacitor.isNativePlatform()) {
      this.initCapacitorPlugins();
      await this.initSessionVaultConfig();
    }
  }

  private initTranslate() {
    this.translate.setDefaultLang('de');
    this.translate.use('de');
  }

  private async initSentry(dist: string) {
    const release = `alberta.mobile@${this.environmentService.version}_${this.environmentService.getCommitHash}`;
    const sentryOptions = {
      dsn: environment.sentry.dsn,
      environment: this.environmentService.branch,
      release,
      dist,
      ignoreErrors: [
        'Twilsock: request timeout',
        'Timeout of 60000ms exceeded calling create on authentication',
        'Timeout of 60000ms exceeded',
        'src/vendor/mobiscroll/js/mobiscroll.custom-4.0.0',
        'NotAuthenticated: Not authenticated',
        /Loading Chunk \d*/,
        // sentry
        'Error: Forbidden', // Twilio
        'Error: disconnected Error: disconnected',
        'transition is invalid while previous transition is still in progress',
        'disconnected (status: 0, code: 0)',
        // tslint:disable-next-line:quotemark
        "Can't connect to twilsock",
      ],
    };
    SentryCapacitor.init<SentryAngular.BrowserOptions>(sentryOptions, SentryAngular.init);
  }
  private async initCapacitorPlugins() {
    this._statusBarService.setColorFromHex('#ffffff');
    await SplashScreen.hide();
    this.appflowLiveUpdatesService.sync();
  }
  // Session Vault Services Emits Lock Events, when Vault is locked.
  private subscribeToSessionVaultLockStatus() {
    this.sessionVault.locked.subscribe(async locked => {
      if (locked && this.platform.is('hybrid') && (await this.sessionVault.canUnlock())) {
        this.isLocked = true;
      } else if (!locked) {
        this.isLocked = false;
      }
    });
  }
  // We recheck the lock status on platform resume, as the lock screen should be shown
  private subscribeToPlatformResumeToCheckLockStatus() {
    this.platform.resume.subscribe(async () => {
      if (!this.sessionVault.vaultInitialized) {
        return;
      }
      if (await this.sessionVault.canUnlock()) {
        this.isLocked = true;
      }
    });
  }

  private async initSessionVaultConfig() {
    const isLockScreenEnabled = await this._tenantConfigService.lockscreenEnabled();
    const lockScreenAfterMillisecondsInBackground =
      await this._tenantConfigService.lockScreenAfterMillisecondsInBackground();
    const shouldHideContentsInBackground = await this._tenantConfigService.shouldHideContentsInBackground();

    if (isLockScreenEnabled) {
      await this.sessionVault.initialize();
      await this.sessionVault.saveSessionInVault(true);
      const canUseBiometrics = await this.sessionVault.canUseBiometrics();
      const canUseSystemPasscode = await this.sessionVault.canUseSystemPasscode();
      this.subscribeToSessionVaultLockStatus();
      this.subscribeToPlatformResumeToCheckLockStatus();
      if (canUseBiometrics) {
        await this.sessionVault.setUnlockMode('BiometricsWithPasscode');
      } else if (canUseSystemPasscode) {
        await this.sessionVault.setUnlockMode('SystemPasscode');
      }
      if (lockScreenAfterMillisecondsInBackground) {
        await this.sessionVault.setLockscreenTimeout(lockScreenAfterMillisecondsInBackground);
      }
    }
    if (shouldHideContentsInBackground) {
      await this.sessionVault.hideContentsInBackground(true);
    }
  }

  private initDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log('start init deeplinks');
        try {
          const url = new URL(event.url);
          const path = url.pathname;
          const protocol = url.protocol;
          // need to filter for https links, appauth links handled in auth.factory.ts
          if (!path || protocol !== 'https:') {
            return;
          }
          this.authService.redirectUrl = path;
          this.router.navigateByUrl(path);
        } catch (error) {
          window.logger.error('app.component appUrlOpen failed to open deep link', error);
        }
      });
    });
  }
}
