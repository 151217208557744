import { IUser } from '@alberta/konexi-shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { AttachmentFeathersCallbacks } from 'src/app/shared/services/attachment/attachment-feathers-callback';

import { AttachmentDto } from '../../models/attachment/attachment-dto.model';
import { AttachmentModelName, PatientModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { AttachmentService } from '../attachment/attachment.service';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { EventService } from '../event.service';

import { StateRegistry } from '@common/state/state-registry';
import { Query } from '@feathersjs/feathers';
import { PatientService } from '@services/patient.service';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { trace } from 'src/app/common/tracing/function-call-tracer';
import { IDatabaseSynchronizer } from '../contracts/sync/database-synchronizer_T';
import { SyncService } from './sync.service';

@Injectable()
export class AttachmentSyncService extends SyncService<AttachmentDto> {
  constructor(
    private _attachmentService: AttachmentService,
    private _attachmentFeathersCallbacks: AttachmentFeathersCallbacks,
    private _patientService: PatientService,
    appController: AppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<AttachmentDto>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync,
    private _stateRegistry: StateRegistry
  ) {
    super(AttachmentModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(): boolean {
    return false;
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await super.setup(feathersAppProvider);
    this._service.removeAllListeners('created');
    this._service.removeAllListeners('updated');
    this._service.removeAllListeners('patched');
    this._service.removeAllListeners('removed');
    this.subscribetoWebsocketEvents();
  }

  private subscribetoWebsocketEvents() {
    this._service.on('created', async attachment => {
      this._trackerService.debugSyncAttachmentSyncWsCreated(attachment._id);
      if (!this.platformSync.canBeSynced) {
        return;
      }
      await this._attachmentFeathersCallbacks.onCreated(attachment);
      if (this.platformSync.isCordova) {
        await this.downloadIfMySwodocImage(attachment);
        this.createNotification(attachment);
      }
    });

    this._service.on('removed', item => {
      this._trackerService.debugSyncAttachmentSyncWsRemoved(item._id);
      this._attachmentFeathersCallbacks.onRemoved(item);
    });
  }

  private async createNotification(attachment: AttachmentDto) {
    const isMyAttachment = await this.isMyAttachment(attachment);
    const isNotifiableAttachmentType =
      attachment.metadata.type === 7 || // CareProposal
      attachment.metadata.type === 3 || // PatientAgreement
      attachment.metadata.type === 8; // DoctorDelegation

    if (isMyAttachment && isNotifiableAttachmentType) {
      this._stateRegistry.createBySync(AttachmentModelName, 'notification', attachment);
    }
  }

  protected async afterSync(syncState: ISyncState<AttachmentDto>) {
    try {
      if (syncState.created && syncState.created.length) {
        if (this.platformSync.isCordova) {
          await Promise.all(
            syncState.created.map(async attachment => {
              if (attachment.metadata.archived) {
                return;
              }
              await this.downloadIfMySwodocImage(attachment);
              this.createNotification(attachment);
            })
          );
        }
      }

      if (syncState.deleted && syncState.deleted.length) {
        for (const attachment of syncState.deleted) {
          await this._attachmentFeathersCallbacks.onArchived(attachment._id);
        }
      }
    } catch (error) {
      window.logger.error('SYNC AFTERSYNC ATTACHMENTS', error);
    }
  }

  public async countDocuments(user: IUser): Promise<number> {
    const query: any = { $limit: 0, timestamp: { $gt: new Date(0) } };
    if (this._workItem.deletable) {
      query['metadata.archived'] = { $ne: true };
    }

    const result = await this._service.find(this.getParamsWithAuth(user, { query }));

    return result.total || result.length;
  }

  @trace()
  protected async syncInternal(
    token: ICancellationToken,
    user: any,
    timestamp: Date,
    initialSyncStart: Date,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void,
    resetSyncTimestamp: (data) => Promise<void>
  ): Promise<any> {
    if (!this._service) {
      throw new Error(
        `Service with name ${this._name} could not be found. Please specify a correct name for your synchronisation
        service or take care your service instance is running.`
      );
    }

    if (this.ignoreSync) {
      return;
    }

    token.syncCompleted[this._name] = false;
    let totalItemsTosync = 0;
    try {
      const query: Query = {
        $limit: 0,
        timestamp: { $gt: timestamp },
        $sort: { timestamp: 1 },
      };

      if (this._workItem.deletable && timestamp <= initialSyncStart) {
        // Initial Sync not completed
        // Archived items should not be in the local database.
        // It is needed to sync archived items anyway if they changed after initialSyncStart, as they could have been
        // archived recently and we might have an outdated, non-archived version of the same item in the database
        // that needs to be updated (deleted)
        query.$or = [{ 'metadata.archived': { $ne: true } }, { timestamp: { $gt: initialSyncStart } }];
      }

      let result: any;
      // If the result is not paginated we need to query at least once since the query before limits to 0
      let lastSyncedTimestamp = timestamp;
      let progress = 0;

      if (!token.cancelled.get()) {
        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, query)));
        resetServiceRepeats(token, this._name);
        totalItemsTosync = this.isPaginated(result) ? result.total : result.length;

        this._eventService.dispatchEvent(new SyncProgressEvent(this._name, totalItemsTosync, progress));
      }
      this._trackerService.debugSyncAttachmentTotalItemsToSync(totalItemsTosync);

      // We need to wait for the patient sync to finish before we can sync attachments
      // to decide which to download
      while (token.syncCompleted[PatientModelName] !== true) {
        await this.sleep(1000);
      }

      while (totalItemsTosync > progress) {
        await this.sleep();

        query['$limit'] = this.limit;
        query['timestamp'] = { $gt: lastSyncedTimestamp };
        if (lastSyncedTimestamp > initialSyncStart) {
          delete query.$or;
        }

        if (token.cancelled.get()) {
          break;
        }

        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, query)));
        resetServiceRepeats(token, this._name);

        const data: AttachmentDto[] = this.isPaginated(result) ? result.data : result;

        const syncState = await (this._workItem as IDatabaseSynchronizer<AttachmentDto>).sync(data, token);
        await this._dispatcher.sync(this._name, syncState);
        const payload = [...syncState.created, ...syncState.deleted, ...syncState.updated];

        await resetSyncTimestamp({ payload, documentCount: result.documentCount });
        await this.afterSync(syncState);

        try {
          progress = progress + payload.length;
          this._eventService.dispatchEvent(new SyncProgressEvent(this._name, totalItemsTosync, progress));
        } catch (e) {}
        this.limit = this.isPaginated(result) ? result.limit : this.limit;

        const lastUploadDate = data.length ? data[data.length - 1].uploadDate : lastSyncedTimestamp;
        if (lastUploadDate === lastSyncedTimestamp && data.length === this.limit) {
          // Prevents infinite loops by checking timestamps:
          // If the timestamp of the most recently synced item (lastSyncedTimestamp)
          // matches the timestamp of the current batch's last item (lastUploadDate),
          // syncing must fail. This ensures we don't repeatedly request the same batch in subsequent rounds.
          throw new Error(
            `Sync failed, more than ${this.limit} items with identical uploadDate ${lastUploadDate} found`
          );
        }
        lastSyncedTimestamp = lastUploadDate;
      }

      token.syncCompleted[this._name] = true;

      if (token.cancelled.get()) {
        delete token.serviceRepeats[this._name];
        throw {
          reason: 'cancelled',
          service: this._name,
          progress: `progress: ${progress}`,
          level: LogLevel.silent,
        };
      }
    } catch (error) {
      window.logger.error(`SYNCSERVICE SYNC Error/ ${this._name}`, error, error.level);
      throw error;
    }
  }
  /**
   * downloads the attachment binary data if it is part of a document of a patient of the current user
   */
  private async downloadIfMySwodocImage(attachment: AttachmentDto) {
    const attachmentTypeKey = attachment.metadata.type;
    if (
      attachmentTypeKey !== 100 && // SwodocImage
      attachmentTypeKey !== 101 && // SwodocPicture
      attachmentTypeKey !== 102 && // SwodocSignature
      attachmentTypeKey !== 103 // SwodocTherapyProgress
    ) {
      return;
    }
    if (await this.isMyAttachment(attachment, true)) {
      this._trackerService.debugSyncAttachmentMyAttachmentsDownloadStart(attachment._id);
      await this._attachmentService.download(attachment._id, attachment.contentType);
      this._trackerService.debugSyncAttachmentMyAttachmentsDownloadDone(attachment._id);
    }
  }
  /**
   * This also sets the patient property of the attachment!
   */
  private async isMyAttachment(attachment: AttachmentDto, isSwodoc = false): Promise<boolean> {
    const myPatients = await this._patientService.getMyPatients();
    const patient = myPatients
      .filter(myPatient => !myPatient.deactivationReason && myPatient.accountingStatus)
      .find(
        myPatient =>
          attachment.metadata.patientId &&
          myPatient._id === attachment.metadata.patientId &&
          (isSwodoc ? attachment.metadata.auditId != null : attachment.metadata.auditId == null)
      );
    // If you find out why this necesarry please document it
    if (patient && !isSwodoc) {
      (attachment as any).patient = patient;
    }

    return patient != null;
  }
}
