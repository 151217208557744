import { Injectable } from '@angular/core';
import { ConfigDB } from 'src/app/common/repository/databases';
import type { ConfigDto } from 'src/app/shared/models/config/config-dto.model';
import { QueryService } from 'src/app/shared/services/query/query.service';

import {
  ICareboxConfig,
  IConfig,
  IDeactivationReasonConfig,
  IDeliveryInformationConfig,
  IKnowledgeBaseConfig,
  IListConfig,
  IPatientDataConfig,
  IPatientStatus,
  IPostalCodeInfoConfig,
  IShippingInformation,
  ISpecializedServiceProviderBranch,
  IStorageLocation,
} from '@alberta/konexi-shared';
import { DocumentSaveInteractionType } from '@alberta/konexi-shared/dist/general/enum/document-save-interaction-type';
import type { IRoleTranslation } from './caregiver-role-translation';

// tslint:disable-next-line:max-line-length
@Injectable({ providedIn: 'root' })
export class TenantConfigService {
  private _config: ConfigDto;
  constructor(private _queryService: QueryService) {}

  public async getTenantConfig(): Promise<IConfig | null> {
    if (!this._config) {
      const config = await this._queryService.getAll<ConfigDto>(ConfigDB);
      if (config?.[0]) {
        this._config = config[0];
      }
    }
    return this._config;
  }

  public updateTenantConfig(config: IConfig): void {
    if (!config) {
      return;
    }
    this._config = config as ConfigDto;
  }

  public async showResmedTenant(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showResmedTenant);
  }
  public async getWoundScales(): Promise<number[]> {
    const config = await this.getTenantConfig();
    return config?.woundScaleConfig?.woundScalesInMillimeter;
  }

  public async showDevices(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showDevices);
  }

  public async showIntegratedCare(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showIntegratedCare);
  }

  public async showCareProposal(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showCareProposal);
  }

  public async specializedServiceProviderEnabled(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.specializedServiceProviderConfig?.enabled);
  }

  public async showLimitedReport(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.disableReports);
  }

  public async getPatientDataConfig(): Promise<IPatientDataConfig | undefined> {
    const config = await this.getTenantConfig();
    return await config?.patientData;
  }

  public async getDeactivationReasonConfig(): Promise<IDeactivationReasonConfig[] | undefined> {
    const config = await this.getTenantConfig();
    return await config?.patientData?.deactivationReasonConfig;
  }

  public async hashAudits(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.hashAudit);
  }

  public async saveImagesAtAudit(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.document?.dontSaveImagesAtAudit);
  }

  public async completeAuditsOnCreate(): Promise<DocumentSaveInteractionType> {
    const config = await this.getTenantConfig();
    if (config?.document?.completeOnCreate == null || typeof config?.document?.completeOnCreate === 'boolean') {
      return Boolean(config?.document?.completeOnCreate)
        ? DocumentSaveInteractionType.PromptForCompleteOrSave
        : DocumentSaveInteractionType.NoPrompt;
    }
    return config?.document?.completeOnCreate;
  }

  public async getDeliveryInformation(): Promise<IDeliveryInformationConfig[]> {
    const config = await this.getTenantConfig();
    return config?.deliveryInformation;
  }

  public async showIntegratedCareAbo(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showAbo);
  }

  public async showRegionFilter(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showRegionFilter);
  }

  public async showStatusFilter(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showStatusFilter);
  }

  public async usedirectOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.directOrder);
  }
  public async canEditInsuranceContract(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.insuranceContract?.canEdit;
  }
  public async calculateContractOnStandardCareProposal(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.contractCalculationOnStandardCareProposal);
  }

  public async canCalculateByContractArticleGroup(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.integratedCare?.contractArticleGroupCalculation;
  }

  public async canAddAddtionalFieldNurse(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.patient?.canAddAdditionalFieldNurse;
  }

  public async showHospitalPrescriberToggle(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return !!config?.orderConfig?.showOrderPrescriberToggle;
  }

  public async showSendOrderDialog(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.showSendOrderDialog ?? true;
  }

  public async showCrossTherapyArticleSearch(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.integratedCare?.showCrossTherapyArticleSearch);
  }

  public async getClassificationLabel(): Promise<string> {
    const config = await this.getTenantConfig();
    return config?.classificationLabel;
  }

  public async classificationRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.classificationRequired);
  }

  public async deliveryInformationRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();

    return Boolean(config?.deliveryInformationRequired);
  }

  public async getPatientClassifications(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.patientClassifications;
  }

  public async getOrderMaxAnnotationLength(): Promise<number> {
    const config = await this.getTenantConfig();
    return config?.orderConfig.maxAnnotationLength;
  }

  public async getShippingInformation(): Promise<IShippingInformation[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.shippingInformation;
  }
  public async showPatientDeliveryInformation(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config?.patientData?.showDeliveryInformation;
  }
  public async showCalorieDemand(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config?.patientData?.showCalorieDemand;
  }

  public async hideBuildNumber(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.hideBuildNumber);
  }
  public async mustSetDeliveryDate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.orderConfig?.deliveryDateEmpty);
  }
  public async canSetDeliveryDateUnknown(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.deliveryDateUnknown);
  }
  public async getStorageLocations(): Promise<IStorageLocation[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.storageLocation;
  }
  public async showIntegratedCareStartDate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showStartDate);
  }

  public async keepShoppingCart(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.keepShoppingCart);
  }

  public async alwaysShowStandardCareProposalTab(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.alwaysShowStandardCareProposalTab);
  }

  public async showNativeShareButton(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.share?.shareAttachments);
  }

  public async showNativeUrlShareButton(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.share?.shareUrls);
  }

  public async getBrowserShareTarget(): Promise<string> {
    const config = await this.getTenantConfig();
    return config?.share?.browserShareTarget;
  }

  public async showNotAutomaticDelivery(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showNotAutomaticDelivery);
  }

  public async showFollowUpCareToggle(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showFollowUpCareToggle);
  }

  public async transmitIntegratedCare(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.transmitIntegratedCare);
  }

  public async canAddPrescriptionRequest(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.prescription?.canAddPrescriptionRequest);
  }

  public async canQueryStock(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showStock);
  }

  public async showDiagnose(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showDiagnose);
  }

  public async showStomaCreationDate(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.showStomaCreationDate);
  }

  public async useAdvancedReturnDeliveryProcess(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.returnDeliveryConfig?.useAdvancedProcess);
  }

  public async shouldShowMonthlyOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.showMonthlyOrder);
  }

  /**
   * Always send
   * (see documentation: "Abschicken von Versorgungen")
   */
  public async isCustomerGroupAlwaysSend(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.transmitIntegratedCare);
  }

  /**
   * Never send
   * (see documentation: "Abschicken von Versorgungen")
   */
  public async isCustomerGroupNeverSend(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.integratedCare?.transmitIntegratedCare) && !Boolean(config?.integratedCare?.showAbo);
  }

  /**
   * Send if abo changed
   * (see documentation: "Abschicken von Versorgungen")
   */
  public async isCustomerGroupSendOnAboChange(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return !Boolean(config?.integratedCare?.transmitIntegratedCare) && Boolean(config?.integratedCare?.showAbo);
  }

  // get role-translation from config DB
  public async getRoleTranslation(): Promise<IRoleTranslation> {
    const config = await this.getTenantConfig();
    const rolesFromConfig = config.caregiverRoles;
    const defaultRoleTranslation: IRoleTranslation = {
      legal_carer: {
        de: 'Gesetzlicher Betreuer',
      },
    };
    // return default role if not set
    if (rolesFromConfig === undefined || rolesFromConfig === null) {
      return defaultRoleTranslation;
    }
    if (rolesFromConfig.length === 0) {
      return defaultRoleTranslation;
    }
    const roleTranslations: IRoleTranslation = {};
    rolesFromConfig.forEach(role => {
      // set default for de if not set
      if (role.translation === undefined) {
        roleTranslations[role.key] = {
          de: role.key,
        };
      } else {
        roleTranslations[role.key] = role.translation;
      }
    });
    return roleTranslations;
  }

  public async showKnowledgeBase(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showKnowledgeBase);
  }

  public async getKnowledgeBaseConfig(): Promise<IKnowledgeBaseConfig> {
    const config = await this.getTenantConfig();
    return config?.knowledgeBaseConfig;
  }

  public async hasDeliveryNote(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.hasDeliveryNote);
  }

  public async hasPatientDeliveryNote(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.patientData?.hasDeliveryNote);
  }

  public async getDeliveryNoteMaxLength(): Promise<number> {
    const config = await this.getTenantConfig();
    if (config.orderConfig?.deliveryNoteMaxLength) {
      return config.orderConfig.deliveryNoteMaxLength;
    } else {
      return 128;
    }
  }
  public async isIntegratedCareArchiveAllowed(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.allowArchive);
  }

  public async getPatientStatuses(): Promise<IPatientStatus[] | undefined> {
    const config = await this.getTenantConfig();
    return config?.patientData?.statuses;
  }

  public async getIntegratedCareMaxAnnotationLength(): Promise<number> {
    const config = await this.getTenantConfig();
    return config?.integratedCare?.maxAnnotationLength;
  }

  public async showRequiredDocumentsBasedOnStandardCareProposal(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showRequiredDocumentsBasedOnStandardCareProposal);
  }

  public async getPostalCodeInfo(): Promise<IPostalCodeInfoConfig[]> {
    const config = await this.getTenantConfig();
    return config?.postalCodeInfo;
  }

  public async getPatientAttributeConfig(): Promise<IConfig['patientData']['attributeConfig']> {
    const config = await this.getTenantConfig();
    return config?.patientData?.attributeConfig || [];
  }

  public async canEditDoctor(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.canEditDoctor);
  }

  public async canSendAlbertaConnectDocuments(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.albertaConnect?.sendDocument);
  }

  public async canSaveDocumentAsDraft(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.document?.saveAsDraft);
  }

  public async getPrescriptionRequestAttachmentTypes() {
    const config = await this.getTenantConfig();
    return config?.prescription?.attachmentTypes;
  }

  public async hideGeneralContracts(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.integratedCare?.hideGeneralContracts);
  }

  public async getOrderListConfig(): Promise<IListConfig> {
    const config = await this.getTenantConfig();
    return config?.orderListConfig;
  }

  public async getDocumentListConfig(): Promise<IListConfig> {
    const config = await this.getTenantConfig();
    return config?.documentListConfig;
  }

  public async getServiceTaskListConfig(): Promise<IListConfig> {
    const config = await this.getTenantConfig();
    return config?.serviceTaskListConfig;
  }

  public async getCareboxConfig(): Promise<ICareboxConfig | null> {
    const config = await this.getTenantConfig();
    return config?.carebox;
  }

  public async displayMedicalAidArticleNameOnPrescription(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.prescription?.displayMedicalAidArticleName);
  }

  public async canAddMedicalOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.medicalOrder?.canAddMedicalOrder);
  }
  public async getServiceOrderListConfig(): Promise<IListConfig> {
    const config = await this.getTenantConfig();
    return config?.serviceOrderListConfig;
  }

  public async showErpOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.showErpOrder);
  }

  public async showCalendar(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.calendarConfig?.enabled);
  }

  public async showPatientDataCalendarResources(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.patientData?.showCalendarResources);
  }

  public async showAppointmentTypeWoundcenter(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.appointmentConfig?.showWoundcenter);
  }

  public async showResourceForAppointment(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.appointmentConfig?.showResource);
  }

  public async videoChatEnabled(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.videoChat?.enabled);
  }

  public async getOrderTags(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.orderTags?.values;
  }

  public async orderTagsSelectStyle(): Promise<'single' | 'multiple'> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.orderTags?.selectStyle || 'multiple';
  }

  public async orderTagsRequired(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return config.orderConfig.orderTags?.required || false;
  }

  public async useAOKTagPrefillWorkflow(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.useAOKTagPrefillWorkflow);
  }

  public async groupOrder(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.orderConfig?.groupOrder);
  }

  public async shouldShowReferrer(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.patientData?.showReferrer);
  }

  public async getAdditionalReferrers(): Promise<{ id: number; name: string }[]> {
    const config = await this.getTenantConfig();
    return config?.patientData?.additionalReferrers;
  }

  public async specializedServiceProviderBranches(): Promise<ISpecializedServiceProviderBranch[]> {
    const config = await this.getTenantConfig();
    return config?.specializedServiceProviderConfig?.branches || [];
  }

  public async forbidCreationWithPatientStatus(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.forbidCreationWithPatientStatus || [];
  }

  public async onlyAllowSendingWithPrescriptionStatus(): Promise<string[]> {
    const config = await this.getTenantConfig();
    return config?.orderConfig?.onlyAllowSendingWithPrescriptionStatus || [];
  }

  public async lockscreenEnabled(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.lockscreenConfig?.enabled) || false;
  }

  public async lockScreenAfterMillisecondsInBackground(): Promise<number> {
    const config = await this.getTenantConfig();
    return config?.lockscreenConfig?.lockScreenAfterMillisecondsInBackground;
  }

  public async shouldHideContentsInBackground(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.lockscreenConfig?.shouldHideContentInBackground);
  }

  public async disableUnknownPayer(): Promise<boolean> {
    const config = await this.getTenantConfig();
    return Boolean(config?.disableUnknownPayer);
  }
}
